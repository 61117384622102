import './App.css'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import Dashboard from './routes/Dashboard';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Dashboard />} errorElement={<ErrorBoundary />} />
      </>
    )
  );
  return <RouterProvider router={router} />
}

export default App

