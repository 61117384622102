import { useLocation } from "react-router-dom";
import Dictionary from "../Queries/Dictionary";
import Main from "../components/Main";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { getStartEndDates } from "../utils/dashboard";
import dashboardContext from "../context/dashboard";
import History from "../components/History";
const Dashboard = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId')
    const role = queryParams.get('role')
    const communityId = queryParams.get('communityid')
    const { data: communityResult, loading: cLoading } = useQuery(Dictionary.getCommunity, { variables: { id: communityId, userId: userId } })
    const community = communityResult?.community
    const studentsResult = role === 'writer' ? communityResult?.community.author : communityResult?.community.writers
    const [selectedUser, setSelectedUser] = useState()
    const getDictionary = (event) => {
        setSelectedUser(event.target.value)
    }
    const [active, setActive] = useState('Dictionary')
    const [clusteredData, setClusteredData] = useState({})
    const [selectedWeek, setSelectedWeek] = useState('')

    if (cLoading)
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Loading....</div>

    return (
        <dashboardContext.Provider value={{ selectedUser: selectedUser, role: role, userId: userId, communityId: communityId, selectedWeek: selectedWeek, community: community, studentsResult: studentsResult, clusteredData: clusteredData, active: active, setClusteredData: setClusteredData, setSelectedWeek: setSelectedWeek }}>
            <div>
                <div style={{ color: '#ffffff', height: '8vh', backgroundColor: "#5C84A8", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 20px" }}>
                    <h3>{community ? community.title : 'Loading Community...'}</h3>
                    {role === 'writer'
                        ? <h4>{studentsResult?.firstName} {studentsResult?.lastName}</h4>
                        : <select onChange={getDictionary} style={{ height: '30px', borderRadius: '10px', marginRight: '25px', marginLeft: '10px' }}>
                            {studentsResult?.map((student, index) => <option key={index} value={student.id}>{student.firstName} {student.lastName}</option>)}
                        </select>}
                </div>
                <div style={{ display: 'flex', height: '92vh' }}>
                    <div className='sidebar'>
                        <div className='main-sidebar' style={{ backgroundColor: active === 'Dictionary' ? '#5C84A8' : '', color: active === 'Dictionary' ? '#ffffff' : '#333333', margin: '30px 0 15px 0' }} onClick={() => setActive('Dictionary')}>Dictionary</div>
                        <div className='main-sidebar' style={{ backgroundColor: active === 'Progress' ? '#5C84A8' : '', color: active === 'Progress' ? '#ffffff' : '#333333' }} onClick={() => setActive('Progress')}>Weekly Progress</div>
                        {active === 'Progress' && <div style={{ marginLeft: 20, marginTop: 10 }}>
                            {
                                Object.keys(clusteredData).map((key, index) => {
                                    const dateRange = getStartEndDates(key.split("-")[0], key.split("-")[1], key.split("-").pop())
                                    return (
                                        <div key={index} style={{ backgroundColor: key === selectedWeek ? '#5C84A8' : '', color: key === selectedWeek ? '#ffffff' : '#333333' }} onClick={() => setSelectedWeek(key)} className='nested-sidebar'>
                                            {dateRange}
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>}
                    <div className='main-sidebar' style={{ backgroundColor: active === 'History' ? '#5C84A8' : '', color: active === 'History' ? '#ffffff' : '#333333', margin: '15px 0' }} onClick={() => setActive('History')}>Chat History</div>
                    </div>
                    <div style={{ width: '82%', overflowY: 'auto' }}>
                        { active === 'History' ? <History/> : <Main />}
                    </div>
                </div>
            </div>
        </dashboardContext.Provider>
    );
}

export default Dashboard;