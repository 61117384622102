import React from "react";
import errorIcon from "../assets/icons/error.png"
export default function ErrorBoundary() {
    
    return (
        <div style={{ textAlign: "center", marginTop: 50 }}>
            <img src={errorIcon} alt='error'/>
            <h2>Oops!!</h2>
            <h4>Something went wrong!!</h4>
            <p>Use the KF portal to access this application.<br/>Login to kf portal, enter a community and select the analytics section to get the dashboard</p>
        </div>
    )
}