function clusterByWeek(data) {
    const clusteredData = {};
    for (const word in data) {
        if (data.hasOwnProperty(word)) {
            const notes = data[word];
            for (const note in notes) {
                const timestamp = new Date(notes[note].timestamp);
                const year = timestamp.getFullYear();
                const month = timestamp.getMonth() + 1; // Month is zero-based, so add 1
                const week = getWeekNumber(timestamp);
                const weekIdentifier = `${year}-${month}-${week}`; // Format: 'year-month-week'
                if (!clusteredData[weekIdentifier]) {
                    clusteredData[weekIdentifier] = {
                        [word]: {}
                    };
                }
                if (!(word in clusteredData[weekIdentifier])) {
                    clusteredData[weekIdentifier][word] = {}
                }
                clusteredData[weekIdentifier][word][note] = notes[note]
            }
        }
    }
    const sortedKeys = Object.keys(clusteredData)
        .map((key) => {
            const [year, month, week] = key.split('-').map(Number);
            return {
                key,
                timestamp: new Date(year, month - 1, week * 7).getTime(),
            };
        })
        .sort((a, b) => b.timestamp - a.timestamp) // Step 2: Sort keys in descending order
        .map((item) => item.key);
    const sortedObject = {};

    for (const key of sortedKeys) {
        sortedObject[key] = clusteredData[key];
    }

    return sortedObject;
}

// Function to get week number
function getWeekNumber(date) {
    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOffset = startOfMonth.getDay();

    return Math.floor((d.getDate() + dayOffset) / 7) + 1;
}

function formatDate(date) {
    const day = date.getDate();
    const monthAbbreviation = date.toLocaleDateString(undefined, { month: 'short' });
    const year = date.toLocaleDateString(undefined, { year: 'numeric' });
    return `${day} ${monthAbbreviation}, ${year}`;
}

function getStartEndDates(year, month, weekNumber) {
    const firstDayOfMonth = new Date(year, month - 1, 1); // Months are 0-indexed
    const daysToAdd = (weekNumber - 1) * 7;
    const startDateOfWeek = new Date(firstDayOfMonth.getTime() + daysToAdd * 24 * 60 * 60 * 1000); // Convert days to milliseconds
    const endDateOfWeek = new Date(startDateOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000); // Add 6 days
    const formattedStartDate = formatDate(startDateOfWeek);
    const formattedEndDate = formatDate(endDateOfWeek);

    return `${formattedStartDate} - ${formattedEndDate}`
}
export { clusterByWeek, getStartEndDates }