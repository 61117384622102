import sort from '../assets/icons/sort.svg'

export default function WordDictionary({ dictionary, sortedData, updateSortedData, updateDictionary }) {
    const getDate = (timestamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
        return formattedDate
    }

    const getTotalCount = (notes) => {
        let count = 0
        for (const note in notes) {
            count += notes[note].count
        }
        return count
    }

    const sortWord = () => {
        let sortedObject = {};
        if (sortedData.word === 'desc') {
            const sortedKeys = Object.keys(dictionary).sort();
            for (const key of sortedKeys) {
                sortedObject[key] = dictionary[key];
            }
            updateSortedData({ ...sortedData, word: 'asc' })
        }
        else {
            const sortedKeysDesc = Object.keys(dictionary).sort((a, b) => b.localeCompare(a));
            for (const key of sortedKeysDesc) {
                sortedObject[key] = dictionary[key];
            }
            updateSortedData({ ...sortedData, word: 'desc' })
        }
        updateDictionary(sortedObject)
    }

    const sortCount = () => {
        let sortedObject = {};
        if (sortedData.count === '' || sortedData.count === 'desc') {
            const keyValuePairs = Object.entries(dictionary);

            // Sort the array based on the count returned by getTotalCount
            keyValuePairs.sort((a, b) => {
                const countA = getTotalCount(a[1]);
                const countB = getTotalCount(b[1]);
                return countA - countB;
            });

            // Create a new object with sorted key-value pairs
            sortedObject = Object.fromEntries(keyValuePairs);
            updateSortedData({ ...sortedData, count: 'asc' })
        }
        else {
            const keyValuePairs = Object.entries(dictionary);

            // Sort the array based on the count returned by getTotalCount
            keyValuePairs.sort((a, b) => {
                const countA = getTotalCount(a[1]);
                const countB = getTotalCount(b[1]);
                return countB - countA;
            });

            // Create a new object with sorted key-value pairs
            sortedObject = Object.fromEntries(keyValuePairs);
            updateSortedData({ ...sortedData, count: 'desc' })

        }
        updateDictionary(sortedObject)

    }
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={sortWord}><div style={{display: 'flex', alignItems: 'center', width: '25%'}}>Word<img style={{marginLeft: 30}} src={sort} alt='sort' width={20} height={20}/></div></th>
                        <th onClick={sortCount}><div style={{display: 'flex', alignItems: 'center'}}>Count<img style={{marginLeft: 30}} src={sort} alt='sort' width={20} height={20}/></div></th>
                        <th>Timestamp</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(dictionary).map((item, index) => {
                        return <tr key={index} >
                            <td style={{width: '25%'}}>{item}</td>
                            <td>
                                {getTotalCount(dictionary[item])}
                            </td>
                            <td>
                                {Object.keys(dictionary[item]).map((note, index) =>
                                    <div style={{ marginBottom: "10px" }} key={index}>{getDate(dictionary[item][note].timestamp)}</div>)}
                            </td>
                            <td>
                                {Object.keys(dictionary[item]).map((note, index) =>
                                    <div key={index} style={{ marginBottom: "10px" }}>
                                        <a href={`${process.env.REACT_APP_KF6_URL}/contribution/${note}/${dictionary[item][note].viewId}`} rel="noreferrer" target='_blank'>{dictionary[item][note].title}</a>
                                    </div>
                                )}
                            </td>

                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

